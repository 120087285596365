export interface MetaInterface {
  title: string;
  description: string;
  image?: string;
  url?: string;
  keywords?: string;
}

export const metaGenerator = (meta: MetaInterface) => {
  const metaTitle = meta.title;
  const metaDescription = meta.description || "";
  const metaImage = meta.image || "";
  const metaUrl = meta.url || "";
  const metaKeywords = meta.keywords || "";
  const arr: any[] = [
    // {
    //   hid: "description",
    //   name: "description",
    //   content: metaDescription,
    // },
    {
      hid: "keywords",
      name: "keywords",
      content: metaKeywords,
    },
    // {
    //   hid: "og:title",
    //   name: "title",
    //   property: "og:title",
    //   content: metaTitle,
    // },
    {
      hid: "og:type",
      property: "og:type",
      content: "website",
    },
    // {
    //   hid: "og:description",
    //   property: "og:description",
    //   content: metaDescription,
    // },
    {
      hid: "og:image",
      property: "og:image",
      content: metaImage,
    },
    {
      hid: "og:url",
      property: "og:url",
      content: metaUrl,
    },
    // {
    //   hid: "og:site_name",
    //   property: "og:site_name",
    //   content: metaTitle,
    // },

    {
      hid: "twitter:card",
      name: "twitter:card",
      content: "summary",
    },
    // {
    //   hid: "twitter:site",
    //   name: "twitter:site",
    //   content: metaTitle,
    // },
    // {
    //   hid: "twitter:title",
    //   name: "twitter:title",
    //   content: metaTitle,
    // },
    {
      hid: "twitter:description",
      name: "twitter:description",
      content: metaDescription,
    },
    {
      hid: "twitter:image",
      name: "twitter:image",
      content: metaImage,
    },

    // {
    //   hid: "name",
    //   itemprop: "name",
    //   content: metaTitle,
    // },
    {
      hid: "description",
      itemprop: "description",
      content: metaDescription,
    },
    {
      hid: "image",
      itemprop: "image",
      content: metaImage,
    },
  ];

  if (metaTitle) {
    arr.push(
      ...[
        // {
        //   hid: "og:title",
        //   name: "title",
        //   property: "og:title",
        //   content: metaTitle,
        // },
        {
          hid: "og:site_name",
          property: "og:site_name",
          content: metaTitle,
        },
        {
          hid: "twitter:site",
          name: "twitter:site",
          content: metaTitle,
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: metaTitle,
        },
        {
          hid: "name",
          itemprop: "name",
          content: metaTitle,
        },
      ]
    );
  }
  return arr;
};
